import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Heading } from '@womcorp/wom-ui-kit/build/lib';
import { v4 as uuidv4 } from 'uuid';
import {
  ProductsWrapper,
  ProductCard,
  InfoText,
  AddresStyled,
  AddresText,
  NameWrapperFlex,
  PlanSpeedFlex,
  BillyngCycleStyled,
  InfoWrapperFlex,
  AddresWrapperFlex,
  AddresTextFlex,
  ProductCardWrapper,
  ConsumptionContainer,
  SelectorLabelStyled,
  DivisorStyled,
} from './styled';
import { ReactComponent as AddresIcon } from '../../../../../assets/images/adress.svg';
import { ReactComponent as ArrowsIcon } from '../../../../../assets/images/arrows.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/images/info.svg';
import { Flex } from '../../../../../utils/flexContainers.styled';
import Selector from '../../../../Selector/Selector';

const ProductsList = ({ products = [] }) => {
  const [productsOptions, setProductsOptions] = useState(
    products.map?.((product) => ({
      ...product,
      text: product.address,
      key: uuidv4(),
    })) || []
  );
  const [selectedProduct, setSelectedProduct] = useState(
    productsOptions[0] ?? {}
  );

  useEffect(() => {
    setProductsOptions(
      products.map?.((product) => ({
        ...product,
        text: product.address,
        key: uuidv4(),
      })) || []
    );
  }, [products]);

  const handleCycleDate = () => {
    const startDayMoment = moment(
      selectedProduct.billingCycle?.start,
      'DD/MM/YYYY'
    );
    const finishDayMoment = moment(
      selectedProduct.billingCycle?.finish,
      'DD/MM/YYYY'
    );

    if (startDayMoment.isValid() && finishDayMoment.isValid()) {
      const startDay = startDayMoment.format('DD/MM/YY');
      const finishDay = finishDayMoment.format('DD/MM/YY');
      return (
        <>
          <span>Entre el</span>
          {` ${startDay} y el ${finishDay}`}
        </>
      );
    }
    return null;
  };

  return (
    <ProductsWrapper>
      {selectedProduct.consumption && (
        <ConsumptionContainer oneProduct={products.length === 1}>
          <b>
            {selectedProduct.consumption.used}{' '}
            {selectedProduct.consumption.unity}
          </b>
          <span>Navegación Ilimitada</span>
        </ConsumptionContainer>
      )}
      {products.length > 1 && (
        <>
          <DivisorStyled />
          <Selector
            options={productsOptions}
            onSelected={({ value }) => setSelectedProduct(value)}
            label={
              <SelectorLabelStyled>
                Tienes <b>{products.length} cuentas</b> con nosotros
              </SelectorLabelStyled>
            }
            optionIcon={<AddresIcon />}
            selectedOptionIcon={<AddresIcon />}
          />
        </>
      )}
      <ProductCardWrapper>
        <ProductCard key={selectedProduct.key}>
          <AddresWrapperFlex center>
            <AddresStyled>
              <AddresTextFlex center>
                <AddresIcon />
                <AddresText title={selectedProduct.address}>
                  {selectedProduct.address}
                </AddresText>
              </AddresTextFlex>
            </AddresStyled>
          </AddresWrapperFlex>
          <NameWrapperFlex center>
            <Heading type="H1">{selectedProduct.name}</Heading>
          </NameWrapperFlex>
          {/* <PlanSpeedFlex column fulWidth>
            <Flex column fulWidth>
              <Flex center>
                <span>{selectedProduct.downlink}</span>
                <ArrowsIcon />
              </Flex>
              <p>de velocidad</p>
            </Flex>
          </PlanSpeedFlex> */}
          <BillyngCycleStyled>{handleCycleDate()}</BillyngCycleStyled>
          <InfoWrapperFlex center>
            <InfoText>
              {/* <InfoIcon />
              {`La velocidad de carga y descarga serán de `}
              {selectedProduct.uplink}
              {` mega bites por segundo`} */}
            </InfoText>
          </InfoWrapperFlex>
        </ProductCard>
      </ProductCardWrapper>
    </ProductsWrapper>
  );
};

export default ProductsList;
