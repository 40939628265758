import React from 'react';
import PropTypes from 'prop-types';
import {
  DebtStatusCard,
  AmountContainer,
  DueDateContainer,
  ButtonContainer,
  ButtonStyled,
  NoDebtWrapper,
  NoDebtContainer,
} from './styled';
import NoDebtIcon from '../../../../assets/images/hands-icon.svg';
import { AuthService } from '../../../../services';
import { thousandsSeparator } from '../../../../utils/formatters';
import useUser from '../../../../hooks/useUser';

const DebtStatus = ({ data }) => {
  let content;
  const womPayData = null;
  const { paymentURL: urlDevetel } = useUser();
  const { debtStatus, debtDetails } = data;

  const hasDebt = () => {
    return debtStatus === 'not_paid';
  };

  if (hasDebt()) {
    content = (
      <>
        <DueDateContainer>
          <span>Tienes una deuda pendiente</span>
          <div>
            Vence el: <b>{debtDetails && debtDetails.fechaVencimiento}</b>
          </div>
        </DueDateContainer>
        <AmountContainer>
          <div>MONTO TOTAL</div>
          <span>
            ${thousandsSeparator(debtDetails && debtDetails.montoTotal)}
          </span>
        </AmountContainer>
        <ButtonContainer>
          {!womPayData && (
            <a href={urlDevetel} target="_blank" rel="noreferrer">
              <ButtonStyled variant="primary" size="l" full>
                Paga tu cuenta
              </ButtonStyled>
            </a>
          )}
        </ButtonContainer>
      </>
    );
  } else {
    content = (
      <NoDebtWrapper>
        <NoDebtContainer>
          <img src={NoDebtIcon} alt="Sin Deuda" /> No tienes deudas pendientes
        </NoDebtContainer>
      </NoDebtWrapper>
    );
  }
  return <DebtStatusCard>{content}</DebtStatusCard>;
};

DebtStatus.propTypes = {
  data: PropTypes.shape({
    debtStatus: PropTypes.string,
    debtDetails: PropTypes.shape({
      fechaVencimiento: PropTypes.string,
      montoTotal: PropTypes.number,
    }),
  }).isRequired,
};

export default DebtStatus;
