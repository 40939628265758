import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from '@womcorp/wom-ui-kit/build/lib';
import { device } from '../../../../utils/device';
import { InOutCard } from '../../..';

export const DueDateContainer = styled.div`
  color: ${(p) => p.theme.colors.greyColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  span {
    color: ${(p) => p.theme.colors.textColor};
    font-size: ${rem('20px')};
    font-weight: bold;
    margin-bottom: ${rem('3px')};
  }
  b {
    font-weight: bold;
  }
  @media ${device.tablet} {
    margin-bottom: ${rem('16px')};
  }
`;

export const AmountContainer = styled.div`
  color: ${(p) => p.theme.colors.greyColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;

  margin-right: ${rem('34px')};
  font-size: ${rem('12px')};
  span {
    font-size: ${rem('30px')};
    color: ${(p) => p.theme.colors.accent};
    font-weight: bold;
    margin-top: ${rem('3px')};
  }
  @media ${device.tablet} {
    text-align: center;
    margin-right: 0;
    margin-bottom: ${rem('16px')};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: ${rem('187px')};
  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const DebtStatusCard = styled(InOutCard)`
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  flex-direction: row;
  padding: ${rem('16px')};
  @media ${device.tablet} {
    flex-direction: column;
    padding-left: ${rem('30px')};
    padding-right: ${rem('30px')};
    text-align: center;
    justify-content: space-between;
    align-items: center;
    padding: ${rem('16px')} ${rem('29px')};
  }
`;

export const ButtonStyled = styled(Button)`
  margin: 0;
  padding: ${rem('6px')} ${rem('12px')};
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  @media ${device.tablet} {
    font-size: ${rem('16px')};
    line-height: ${rem('19px')};
    height: ${rem('44px')};
  }
`;

export const NoDebtWrapper = styled.div`
  color: ${(p) => p.theme.colors.textColor};
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  padding: 0 ${rem('16px')};
  img {
    margin-right: ${rem('14px')};
  }
  @media ${device.tablet} {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  ${ButtonStyled} {
    max-width: ${rem('220px')};
    margin-left: auto;
    white-space: nowrap;
    @media ${device.tablet} {
      margin-left: 0;
      font-size: ${rem('14px')};
      line-height: ${rem('18px')};
      height: ${rem('30px')};
      margin-top: ${rem('8px')};
      font-weight: normal;
    }
  }
`;

export const NoDebtContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
    justify-content: center;
  }
`;

export const SetAutomaticPaymentLink = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('20px')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration-line: underline;
  color: ${(p) => p.theme.colors.accent};
  text-align: center;
  margin: ${rem('8px')} 0;
`;

export const EditPreferencesContainer = styled.div`
  font-size: ${rem('14px')};
  line-height: ${rem('18px')};
  color: ${(p) => p.theme.colors.primaryColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${rem('215px')};
  margin-left: auto;
  text-align: center;
  @media ${device.tablet} {
    flex-direction: row;
    margin-top: ${rem('19px')};
    width: 100%;
    margin-left: 0;
    text-align: left;
  }
`;

export const EditPreferencesButtonStyled = styled(Button)`
  padding: ${rem('6px')} ${rem('12px')};

  height: ${rem('30px')};

  border: ${rem('1px')} solid ${(p) => p.theme.colors.primaryColor};
  color: ${(p) => p.theme.colors.primaryColor};
  background-color: ${(p) => p.theme.colors.whiteColor};
  box-sizing: border-box;
  border-radius: ${rem('8px')};
  width: 100%;
  font-size: ${rem('14px')};
  margin-top: ${rem('8px')};
  margin-bottom: 0;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media ${device.tablet} {
    max-width: ${rem('215px')};
    margin-left: ${rem('8px')};
  }
`;
