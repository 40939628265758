import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { device } from '../../utils/device';
import SEO from '../../components/seo';
import PlanError from '../../components/pages/home/PlanInformation/PlanError/PlanError';
import Loading from '../../components/Loading/Loading';
import { AccountService } from '../../services';
import { SiteLayout } from '../../components/Layouts';
import { InOutCardResponsive } from '../../components';
import PlanInformation from '../../components/pages/home/PlanInformation/PlanInformation';
import DebtStatus from '../../components/pages/home/DebtStatus/DebtStatus';
import GoogleTagManager from '../../helpers/GoogleTagManager';

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomLoadingCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const Home = () => {
  const [planData, setPlanData] = useState({
    products: [],
    user: {},
  });
  const [userData, setUserData] = useState(null);
  const [isLoadingPlan, setIsLoadingPlan] = useState(true);
  const [isError, setIsError] = useState(false);
  const [debtStatus, setDebtStatus] = useState(null);

  const fetchPlan = async () => {
    setIsLoadingPlan(true);

    try {
      const [responseService] = await AccountService.getPlanData();
      setPlanData(responseService);
      setUserData(responseService.user);
      AccountService.setUserName(responseService.user);
      GoogleTagManager.pushToDataLayer({
        event: 'pagina-virtual',
        page: '/inicio',
        pageName: 'INICIO',
      });
    } catch ({ status, data }) {
      setIsError(true);
    } finally {
      setIsLoadingPlan(false);
    }
  };

  const fetchDebtStatus = async () => {
    try {
      const [responseService] = await AccountService.getDebtStatus();
      setDebtStatus(responseService);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPlan();

    fetchDebtStatus();
  }, []);

  return (
    <>
      <SEO title="Home" />
      <SiteLayout user={userData}>
        {debtStatus && <DebtStatus data={debtStatus} />}
        {isLoadingPlan && (
          <>
            <LoadingWrapper>
              <CustomLoadingCardWrapper>
                <Loading />
              </CustomLoadingCardWrapper>
            </LoadingWrapper>
          </>
        )}
        {!isLoadingPlan && !isError && <PlanInformation data={planData} />}
        {!isLoadingPlan && isError && <PlanError onRetry={fetchPlan} />}
      </SiteLayout>
    </>
  );
};

export default Home;
