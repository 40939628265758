import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Text, Button } from '@womcorp/wom-ui-kit';
import { InOutCardResponsive } from '../../../..';
import { ReactComponent as Ghost } from '../../../../../assets/images/ghost.svg';
import { device } from '../../../../../utils/device';

const AccountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  @media ${device.laptop} {
    width: 100%;
  }
  h1,
  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const CustomCardWrapper = styled(InOutCardResponsive)`
  max-width: 100%;
  margin-top: 0;
  padding-bottom: ${rem('36px')};
  @media ${device.tablet} {
    max-width: ${rem('470px')};
  }
`;

const CenteredText = styled(Text)`
  text-align: center;
  margin: 0;
`;

const GhostImgWrapper = styled.div`
  margin: 30px;
  text-align: center;
`;

const CustomButtonWrapper = styled.div`
  width: ${rem('300px')};
  margin: 0 auto;
  margin-top: ${rem('7px')};
  max-width: 85%;
`;

const ButtonText = styled.div`
  color: white;
  font-weight: normal;
`;

const CenteredTextBold = styled(CenteredText)`
  margin-bottom: ${rem('16px')};
  font-weight: bold;
`;

const PlanError = ({ onRetry }) => {
  return (
    <AccountWrapper>
      <CustomCardWrapper>
        <GhostImgWrapper>
          <Ghost />
        </GhostImgWrapper>
        <CenteredTextBold>No disponible</CenteredTextBold>
        <CenteredText>
          Por el momento no podemos cargar la información de tu cuenta.
        </CenteredText>
        <CenteredText>Inténtalo nuevamente</CenteredText>
        <CustomButtonWrapper>
          <Button variant="primary" onClick={() => onRetry()} size="l" full>
            <ButtonText>REINTENTAR</ButtonText>
          </Button>
        </CustomButtonWrapper>
      </CustomCardWrapper>
    </AccountWrapper>
  );
};

export default PlanError;
