import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Flex } from '../../../../../utils/flexContainers.styled';
import { device } from '../../../../../utils/device';
import InOutCardResponsive from '../../../../InOutCardResponsive/InOutCardResponsive';

export const ProductsWrapper = styled(Flex)`
  padding: 0;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: nowrap;
  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    z-index: 1;
  }
`;

export const ProductCardWrapper = styled(InOutCardResponsive)`
  color: black;
  padding: 0;
  text-align: center;
  min-height: ${rem('287px')};
  max-width: ${rem('462px')};
  background: transparent;
  box-shadow: 0 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media ${device.tablet} {
    align-items: stretch;
  }
`;

export const ProductCard = styled(InOutCardResponsive)`
  color: black;
  padding: 0;
  text-align: center;
  /* min-height: ${rem('287px')}; */
  background: ${(p) => p.theme.colors.lightGreyColor};
  border: 1px solid #ccc4d2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  @media ${device.tablet} {
    align-items: stretch;
  }
`;

export const ConsumptionContainer = styled.div`
  display: flex;
  justify-content: center;
  color: ${(p) => p.theme.colors.whiteColor};
  flex-wrap: wrap;
  width: 100%;
  padding-left: ${rem('16px')};
  b {
    font-weight: bold;
    padding-right: 7px;
  }
  span {
    width: auto;
    overflow: hidden;
    text-overflow: clip;
    white-space: nowrap;
  }

  @media ${device.tablet} {
    color: ${(p) => p.theme.colors.primaryColor};
    padding-bottom: ${rem('14px')};
    padding-top: ${rem('16px')};
    box-shadow: 0px 2px 4px rgba(45, 20, 65, 0.2);
    background-color: ${(p) => p.theme.colors.whiteColor};
    justify-content: flex-start;
    b {
      color: ${(p) => p.theme.colors.accent};
    }
    ${(p) =>
      p.oneProduct &&
      css`
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      `}
  }
`;

export const InfoWrapperFlex = styled(Flex)`
  background: ${(p) => p.theme.colors.whiteColor};
  color: #76489b;
  border-bottom-left-radius: ${rem('8px')};
  border-bottom-right-radius: ${rem('8px')};
  box-shadow: inset 0 1px 2px -1px ${(p) => p.theme.colors.greyColor};
  font-size: ${rem('13px')};
  width: 100%;
`;

export const InfoText = styled.div`
  text-align: left;
  padding: ${rem('17px')};
  padding-left: ${rem('40px')};
  max-width: ${rem('310px')};
  svg {
    margin-left: ${rem('-27px')};
    position: absolute;
  }
`;

export const AddresWrapperFlex = styled(Flex)`
  width: 100%;
`;

export const AddresStyled = styled.div`
  margin: 0 ${rem('48px')};
  color: ${(p) => p.theme.colors.primaryColor};
  padding: ${rem('16px')} 0;
  border-bottom: 1px solid #ccc4d2;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  width: 80%;
  max-width: ${rem('377px')};
`;

export const AddresTextFlex = styled(Flex)`
  margin: ${rem('4px')};
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  svg {
    margin-right: ${rem('7px')};
    height: 16px;
    width: 16px;
    color: ${(p) => p.theme.colors.accent};
  }
`;

export const AddresText = styled.div`
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

export const NameWrapperFlex = styled(Flex)`
  padding: ${rem('16px')};
  h1 {
    color: ${(p) => p.theme.colors.primaryColor};
    text-align: center;
    margin: 0;
    margin-right: ${rem('3px')};
  }
  span {
    height: 32px;
    width: 73px;
    border-radius: 8px;
    font-weight: bold;
    color: ${(p) => p.theme.colors.whiteColor};
    background-color: ${(p) => p.theme.colors.accent};
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem('23px')};
    margin: 0;
  }
`;

export const PlanSpeedFlex = styled(Flex)`
  width: 100%;
  padding: 0 ${rem('16px')};
  span {
    font-size: ${rem('44px')};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p {
    width: 100%;
    text-align: left;
    word-spacing: ${rem('8px')};
  }
  span,
  p {
    color: ${(p) => p.theme.colors.accent};
    font-weight: bold;
  }
`;

export const BillyngCycleStyled = styled.div`
  color: ${(p) => p.theme.colors.primaryColor};
  font-size: ${rem('14px')};
  padding: 0 ${rem('16px')};
  margin-bottom: ${rem('14px')};
  span {
    font-weight: bold;
  }
`;

export const SelectorLabelStyled = styled.div`
  b {
    font-weight: bold;
  }
`;

export const DivisorStyled = styled.div`
  width: 100%;
  max-width: ${rem('422px')};
  margin: 0 auto;
  height: 1px;
  background-color: white;
  opacity: 0.5;
  margin-top: ${rem('16px')};
  @media ${device.tablet} {
    display: none;
  }
`;
