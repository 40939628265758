import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { motion } from 'framer-motion';
import { device, size } from '../../utils/device';

export const SelectorWrapper = styled.div`
  margin-top: ${rem('16px')};
  position: relative;
  background-color: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    margin-top: ${rem('3px')};
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: ${(p) => p.theme.colors.whiteColor};
    box-shadow: 0px 2px 4px rgba(45, 20, 65, 0.2);
  }
`;

export const SelectorLabel = styled.div`
  color: ${(p) => p.theme.colors.whiteColor};
  cursor: pointer;
  @media ${device.tablet} {
    color: ${(p) => p.theme.colors.primaryColor};
    position: absolute;
    width: 100%;
    height: ${rem('44px')};
    padding: ${rem('12px')};
    padding-right: ${rem('50px')};
    padding-left: ${rem('10px')};
    margin: 0;
    align-items: center;
  }
  @media ${device.mobileS} {
    padding-right: 0;
  }
`;

export const OptionLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconContainer = styled.div`
  svg {
    margin-right: ${rem('7px')};
    height: 16px;
    width: 16px;
    color: ${(p) => p.theme.colors.greyColor};
  }
`;

export const SelectedOptionContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: ${rem('20px')};
  padding-right: ${rem('2px')};
`;

export const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  z-index: 4;
  position: absolute;
  top: 0;
  right: 0;
  transition: border-bottom-right-radius 0.7s ease;
  height: ${rem('44px')};
  width: ${rem('52px')};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: ${(p) => p.theme.colors.whiteColor};

  img {
    transition: transform 0.7s ease;
    width: ${rem('16px')};
    height: ${rem('8px')};
  }

  @media ${device.mobileS} {
    width: ${rem('28px')};
  }
`;

export const SelectorStyled = styled.div`
  position: relative;
  display: flex;
  color: ${(p) => p.theme.colors.primaryColor};
  font-weight: bold;
  background-color: ${(p) => p.theme.colors.whiteColor};
  height: ${rem('44px')};
  padding: ${rem('12px')};
  padding-right: ${rem('50px')};
  padding-left: ${rem('16px')};
  margin-bottom: ${rem('15px')};
  align-items: center;
  border-radius: 8px;
  transition: border-bottom-left-radius 0.7s ease;
  ${IconContainer} svg {
    color: ${(p) => p.theme.colors.accent};
  }

  @media ${device.tablet} {
    margin: 0;
    opacity: 0;
  }
`;

export const SelectorContainer = styled.div`
  position: relative;
  user-select: none;
  cursor: pointer;
  width: ${rem('319px')};
  margin-top: ${rem('15px')};
  ${(p) =>
    p.isOpen &&
    css`
      ${SelectorStyled} {
        border-bottom-left-radius: 0;
      }
      ${ChevronContainer} {
        border-bottom-right-radius: 0;
        @media ${device.tablet} {
          border-top-right-radius: 0;
        }
        &:hover {
          background-color: ${p.theme.colors.lightGreyColor};
        }
        img {
          transform: rotateX(180deg);
        }
      }
    `}
  @media ${device.tablet}{
    width: 100%;
    margin: 0;
  }
`;

export const OptionsWrapper = styled(motion.div)`
  z-index: 3;
  background-color: ${(p) => p.theme.colors.whiteColor};
  position: absolute;
  width: ${rem('319px')};
  height: auto;
  top: 0;
  left: 0;
  box-shadow: 0px 5px 10px rgba(45, 18, 65, 0.15);
  border-radius: 8px;

  overflow: hidden;
  @media ${device.tablet} {
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }
`;

export const AnimationVariants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
};

export const AnimationTransitions = { duration: 0.3 };

export const OptionContainer = styled.div`
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${rem('44px')};
  line-height: ${rem('20px')};
  padding: ${rem('13px')};
  padding-left: ${rem('16px')};
  padding-right: ${rem('18px')};
  border-top: 1px solid #ccc4d2;
  align-items: center;
  color: ${(p) => p.theme.colors.greyColor};
  background-color: ${(p) => p.theme.colors.whiteColor};
  &:hover {
    color: ${(p) => p.theme.colors.primaryColor};
    background-color: ${(p) => p.theme.colors.lightGreyColor};
  }
  &:first-child {
    padding-right: ${rem('50px')};
    border-top: 0;
  }
  @media ${device.tablet} {
    &:hover {
      color: ${(p) => p.theme.colors.greyColor};
      background-color: ${(p) => p.theme.colors.whiteColor};
    }
    &:first-child {
      padding-right: ${rem('18px')};
      border-top: 1px solid #ccc4d2;
    }
  }

  ${(p) =>
    p.selected &&
    css`
      ${OptionLabel} {
        color: ${(p) => p.theme.colors.primaryColor};
        font-weight: bold;
        padding-right: ${rem('2px')};
      }

      ${IconContainer} svg {
        color: ${(p) => p.theme.colors.accent};
      }
    `}
`;
