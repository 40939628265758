import React from 'react';
import { Heading } from '@womcorp/wom-ui-kit';
import {
  PlanInformationWrapper,
  PlanInformationContainer,
  PlanInformationFlex,
  PlanBackgroundDesktop,
  PlanBackgroundMobile,
  BackgroundWrapper,
  PlanInformationContent,
  PlanInformationText,
} from './styled';
import ProductsList from './ProductsList/ProductsList';
import PlanDesktopImg from '../../../../assets/images/fibra-plan-desktop.svg';
import PlanMobileImg from '../../../../assets/images/fibra-plan-mobile.svg';

const PlanInformation = ({ data }) => {
  const numberProducts = data.products?.length;
  const HeaderText = () =>
    numberProducts > 1 ? (
      <Heading type="H1">TUS CUENTAS FIBRA</Heading>
    ) : (
      <Heading type="H1">TU CUENTA FIBRA</Heading>
    );

  return (
    <PlanInformationWrapper>
      <PlanInformationFlex column fullWidth>
        <PlanInformationContainer>
          <BackgroundWrapper>
            <PlanBackgroundDesktop src={PlanDesktopImg} />
            <PlanBackgroundMobile src={PlanMobileImg} />
          </BackgroundWrapper>
          <PlanInformationContent>
            <PlanInformationText>
              <HeaderText />
            </PlanInformationText>
            <ProductsList products={data.products || []} />
          </PlanInformationContent>
        </PlanInformationContainer>
      </PlanInformationFlex>
    </PlanInformationWrapper>
  );
};

export default PlanInformation;
