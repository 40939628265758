import styled, { css } from 'styled-components';
import { rem } from 'polished';
import InOutCardResponsive from '../../../InOutCardResponsive/InOutCardResponsive';
import { Flex, Container } from '../../../../utils/flexContainers.styled';
import { device } from '../../../../utils/device';

export const PlanInformationWrapper = styled(Container)``;

export const PlanInformationFlex = styled(Flex)``;

export const PlanInformationContainer = styled.div`
  margin: 0;
  padding: 0;
  background: transparent;
  @media ${device.tablet} {
    width: 100%;
  }
`;

export const PlanBackgroundDesktop = styled.img`
  display: block;
  @media ${device.tablet} {
    display: none;
  }
`;
export const PlanBackgroundMobile = styled.img`
  display: none;
  @media ${device.tablet} {
    display: block;
  }
`;

export const BackgroundWrapper = styled(InOutCardResponsive)`
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  background: transparent;
  img {
    width: 100%;
  }
  @media ${device.tablet} {
    position: relative;
  }
`;

export const PlanInformationText = styled.div`
  padding: ${rem('18px')};
  z-index: 1;
  width: 100%;
  margin-top: ${rem('32px')};
  padding-bottom: 0;
  h1 {
    text-align: center;
    color: ${(p) => p.theme.colors.whiteColor};
    font-size: ${rem('40px')};
  }
  @media ${device.tablet} {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${rem('16px')};
    padding-bottom: 0;
    position: relative;
    background-color: ${(p) => p.theme.colors.whiteColor};
    box-shadow: 0px 2px 4px rgba(45, 20, 65, 0.2);
    h1 {
      font-size: ${rem('30px')};
    }
    h1,
    p {
      color: ${(p) => p.theme.colors.primaryColor};
      margin: 0;
    }
  }
`;

export const PlanInformationContent = styled(Container)`
  @media ${device.tablet} {
    h1 {
      color: ${(p) => p.theme.colors.primaryColor};
    }
  }
`;

export const MultipleHeading = styled.div`
  margin: ${rem('16px')} 0;
  h1 {
    margin: 0;
  }
  @media ${device.tablet} {
    display: flex;
    justify-content: center;
    margin-bottom: ${rem('2px')};
    h1 {
      margin-right: ${rem('5px')};
    }
  }
  @media ${device.mobileL} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
