import React, { useState, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import {
  SelectorWrapper,
  SelectorLabel,
  SelectorStyled,
  OptionsWrapper,
  OptionContainer,
  OptionLabel,
  IconContainer,
  AnimationTransitions,
  AnimationVariants,
  SelectedOptionContainer,
  ChevronContainer,
  SelectorContainer,
} from './styled';
import Chevron from '../../assets/images/chevron-down.svg';
import useOnClickOutside from '../../hooks/useOnClickOutside';

const Selector = ({
  options,
  onSelected,
  label,
  optionIcon,
  selectedOptionIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]?.text ?? '');
  const [selectedOptionKey, setSelectedOptionKey] = useState(
    options[0]?.key ?? ''
  );
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  const toggling = () => setIsOpen((_) => !_);
  const onOptionClicked = (option, index, list, selected) => () => {
    if (!selected) {
      setSelectedOption(option.text);
      setSelectedOptionKey(option.key);
      onSelected({ value: option, index, list });
    }
    setIsOpen(false);
  };

  return (
    <SelectorWrapper>
      <SelectorLabel onClick={toggling}>
        {label || 'Selecciona una opción'}
      </SelectorLabel>
      <SelectorContainer isOpen={isOpen} ref={ref}>
        <SelectorStyled onClick={toggling} title={selectedOption}>
          {selectedOptionIcon && (
            <IconContainer>{selectedOptionIcon}</IconContainer>
          )}
          <SelectedOptionContainer>{selectedOption}</SelectedOptionContainer>
        </SelectorStyled>
        <ChevronContainer onClick={toggling}>
          <img src={Chevron} alt="" />
        </ChevronContainer>
        <AnimatePresence>
          {isOpen && (
            <OptionsWrapper
              key="content"
              initial="collapsed"
              animate="open"
              exit="collapsed"
              variants={AnimationVariants}
              transition={AnimationTransitions}
            >
              {options.map((option, index, list) => {
                const selected = option.key === selectedOptionKey;
                return (
                  <OptionContainer
                    key={option.key}
                    onClick={onOptionClicked(option, index, list, selected)}
                    selected={selected}
                    title={option.text}
                  >
                    {selected && selectedOptionIcon && (
                      <IconContainer>{selectedOptionIcon}</IconContainer>
                    )}
                    {!selected && optionIcon && (
                      <IconContainer>{optionIcon}</IconContainer>
                    )}
                    <OptionLabel>{option.text}</OptionLabel>
                  </OptionContainer>
                );
              })}
            </OptionsWrapper>
          )}
        </AnimatePresence>
      </SelectorContainer>
    </SelectorWrapper>
  );
};

export default Selector;
